import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Page from "../../../Page.jsx";
import axios from "axios";
import {useFetchDepartments, useFetchMdmCompanies} from "../DataSubscription.hooks.jsx";
import {Box} from "@mui/material";

const COLUMNS = [
    {
        field: "applicationName",
        headerName: "Application Name",
        flex: 1
    },
    {
        field: "companyName",
        headerName: "Company Name",
        flex: 1
    },
    {
        field: "id",
        headerName: "Subscription Number",
        // renderCell: ({value}) => <Chip color={STATE_MAPPER[value]} label={value} clickable={false}/>
        flex: 1
    },
    {
        field: "sec_mkt_key",
        headerName: "Geo Family ID",
        flex: 1
    },
    {
        field: "productDescription",
        headerName: "Product Description",
        flex: 1
    },
    {
        field: "prd_lvl",
        headerName: "Product Level",
        flex: 1
    },
]

const useFetchSubscriptionsList = () => {
    const [loading, setLoading] = useState(true)
    const [subscriptions, setSubscriptions] = useState([])
    const fetchAllSubscriptions = useCallback(async () => {
        const ignitePlusSubscriptionsResults = await axios.get(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/subscriptions?application=ignitePlus`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            }
        })
        const ignitePlusSubscriptions = ignitePlusSubscriptionsResults.data.map(subscription => ({
            ...subscription,
            applicationName: 'Ignite Plus'
        }))
        const defaultSubscriptionsResults = await axios.get(`${process.env.REACT_APP_RIS_API_URL}/data-subscriptions/data-subscriptions/subscriptions?application=default`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${window.localStorage.getItem('access_token')}`
            }
        })
        const defaultSubscriptions = defaultSubscriptionsResults.data.map(subscription => ({
            ...subscription,
            applicationName: 'Default Subscriptions'
        }))

        return [...ignitePlusSubscriptions, ...defaultSubscriptions]
    }, [])

    useEffect(() => {
        fetchAllSubscriptions()
            .then((result) => {
                setSubscriptions(result)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [fetchAllSubscriptions])

    return {subscriptions, loading}
}

const mapCompanyName = (subscriptions, companyList, flatDepartments) => {
    return subscriptions.map((subscription) => {
        const productCode = subscription.sec_prd_key.split('|')[1]
        return ({
            ...subscription,
            companyName: companyList.find(company => company.value === subscription.salesforceid)?.displayName ?? subscription.salesforceid,
            productDescription: flatDepartments.find(item => item.value === productCode)?.displayName ?? productCode
        })
    })
}

export function SubscriptionsList() {
    const {companyList, isCompaniesLoading: companiesLoading} = useFetchMdmCompanies()
    const {subscriptions, loading: subscriptionsLoading} = useFetchSubscriptionsList()
    const {departments, isDepartmentsLoading: departmentsLoading} = useFetchDepartments()
    const [flatDepartments, setFlatDepartments] = useState([])

    useEffect(() => {
        if (departments.length) {
            const flattenedDepartments = departments.flatMap((dept) => {
                return [
                    {displayName: dept.displayName, value: dept.value},
                    ...dept.categories.flatMap((category) => {
                        return [
                            {displayName: category.displayName, value: category.value},
                            ...category.subcategories.map((subcat) => ({
                                displayName: subcat.displayName,
                                value: subcat.value
                            }))
                        ]
                    })
                ]
            })
            setFlatDepartments(flattenedDepartments)
        }
    }, [departments])

    const rows = useMemo(() => {
        if (subscriptions.length && companyList.length && flatDepartments.length) {
            return mapCompanyName(subscriptions, companyList, flatDepartments)
        }
        return []
    }, [subscriptions, companyList, flatDepartments])

    function QuickSearchToolbar() {
        return (
            <Box sx={{p: 1, pb: 0,}}
                 style={{display: "flex", justifyContent: "end", paddingBottom: "10px", borderBottom: "1px solid gray"}}
            >
                <GridToolbarQuickFilter
                    debounceMs={500}
                    size={"medium"}
                    data-testid={"datagrid-quick-filter"}
                    quickFilterParser={(searchInput) => {
                        return [searchInput];
                    }}
                    variant="outlined"
                    sx={{paddingBottom: 0}}
                />
            </Box>
        );
    }

    return <Page name={"Subscriptions List"}>
        <DataGrid
            columns={COLUMNS}
            rows={rows}
            slots={{toolbar: QuickSearchToolbar}}
            getRowId={(row) => row.id}
            loading={companiesLoading || subscriptionsLoading || departmentsLoading}
            autoHeight={true}
            disableVirtualization={!!process.env.REACT_APP_DISABLE_VIRTUALIZATION}
            data-testid={'subscriptions-list-data-grid'}
        />
    </Page>
}